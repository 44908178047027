<template>
    <div>
        <div class="bg-card-bg p-6 rounded-lg py-24 flex items-center justify-center" v-if="loadingState.address">
            <Loader />
        </div>
        <FormulateForm #default="{ hasErrors }" v-else>
            <div class="flex flex-col gap-6">
                <!-- Primary Contact -->
                <div>
                    <h1 class="text-gray-700 font-semibold text-lg mb-3">Primary Address</h1>
                    <div class="bg-card-bg p-6 rounded-lg">
                        <div class="grid grid-cols-2 gap-6">
                            <FormulateInput :disabled="!disableAllField" class="w-full" label="Line 1" placeholder="Line 1" type="text" validation="required" v-model="address.primary_address.line_1" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                            <FormulateInput :disabled="!disableAllField" class="w-full" label="Line 2" placeholder="Line 2" type="text" v-model="address.primary_address.line_2" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                        </div>
                        <div class="grid grid-cols-3 gap-6">
                            <FormulateInput :disabled="!disableAllField" class="w-full" label="City" placeholder="City" validation="required|matches:/^[a-zA-Z\s]+$/" :validation-messages="{ matches: 'City name cannot contain number or any special characters'}" type="text" v-model="address.primary_address.city" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />

                            <!-- <FormulateInput class="w-full" label="Country" placeholder="Country" validation="required" type="text" v-model="address.primary_address.country" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" /> -->

                            <div>
                                <div class="flex">
                                    <h2 class="mb-1 mt-1">Country</h2>
                                    <span class="text-red-500 ml-1 text-lg">*</span>
                                </div>

                                <Dropdown
                                    :disabled="!disableAllField"
                                    placeholder="Country"
                                    :options="countries"
                                    searchable
                                    :colors="{
                                        text: '#282828',
                                        bg: 'white',
                                        svg: '#282828',
                                        border: '#bcbcbc',
                                    }"
                                    :value="address.primary_address.country"
                                    @input="(value) => updateCountry(value, 'primary_address')"
                                    :config="{ label: 'name', trackBy: 'name' }"
                                    maxWidth="100%"
                                    minWidth="100%"
                                    width="102px"
                                    height="20px"
                                    :loading="loadingState.country"
                                />
                                <span v-if="showCountryError" class="countryErrorMsg">
                                    Country is required.
                                </span>
                            </div>

                            <FormulateInput :disabled="!disableAllField" class="w-full" label="Postcode" placeholder="Postcode" validation="required|matches:/^[a-zA-Z0-9\s\-]+$/" :validation-messages="{ matches: 'Only characters supported are alphanumeric, - and spaces' }" type="text" v-model="address.primary_address.zip_code" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                        </div>
                    </div>
                </div>

                <!-- Accounts Contact -->
                <div>
                    <div class="flex gap-2 items-center mb-3">
                        <h1 class="text-gray-700 font-semibold text-lg mr-4">Billing Address</h1>
                        <input @input="(input) => sameAsPrimaryAddress(input.target.checked)" type="checkbox" id="same_as_primary" class="checkbox checkbox-sm" v-model="saveAsPrimary" />
                        <label for="same_as_primary" class="text-gray-600 text-sm"> Same as Primary Address </label>
                    </div>
                    <div class="bg-card-bg p-6 rounded-lg">
                        <div class="grid grid-cols-2 gap-6">
                            <FormulateInput :disabled="!disableAllField || saveAsPrimary" class="w-full" label="Line 1" placeholder="Line 1" type="text" :validation="checkBillingAddressRequired && 'required'" v-model="address.billing_address.line_1" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                            <FormulateInput :disabled="!disableAllField || saveAsPrimary" class="w-full" label="Line 2" placeholder="Line 2" type="text" v-model="address.billing_address.line_2" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                        </div>
                        <div class="grid grid-cols-3 gap-6">
                            <FormulateInput :disabled="!disableAllField || saveAsPrimary" class="w-full" label="City" placeholder="City" validation="required|matches:/^[a-zA-Z\s]+$/" :validation-messages="{ matches: 'City name cannot contain number or any special characters'}" type="text" v-model="address.billing_address.city" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />

                            <!-- <FormulateInput class="w-full" label="Country" placeholder="Country" validation="required" type="text" v-model="address.billing_address.country" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" /> -->
                            <div>
                                <div class="flex">
                                    <h2 class="mb-1 mt-1">Country</h2>
                                    <span v-if="checkBillingAddressRequired" class="text-red-500 ml-1 text-lg">*</span>
                                </div>

                                <Dropdown
                                    :disabled="!disableAllField || saveAsPrimary"
                                    placeholder="Country"
                                    :options="countries"
                                    searchable
                                    :colors="{
                                        text: '#282828',
                                        bg: 'white',
                                        svg: '#282828',
                                        border: '#bcbcbc',
                                    }"
                                    :value="address.billing_address.country"
                                    @input="(value) => updateCountry(value, 'billing_address')"
                                    :config="{ label: 'name', trackBy: 'name' }"
                                    maxWidth="100%"
                                    minWidth="100%"
                                    width="102px"
                                    height="20px"
                                    :loading="loadingState.country"
                                />
                                <span v-if="billingCountryError" class="countryErrorMsg">
                                    Country is required.
                                </span>
                            </div>

                            <FormulateInput :disabled="!disableAllField || saveAsPrimary" class="w-full" label="Postcode" placeholder="Postcode" :validation="checkBillingAddressRequired ? 'required|matches:/^[a-zA-Z0-9\\s\\-]+$/' : 'matches:/^[a-zA-Z0-9\\s\\-]+$/'" :validation-messages="{ matches: 'Only characters supported are alphanumeric, - and spaces' }" type="text" v-model="address.billing_address.zip_code" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex my-4 justify-between w-full" v-if="disableAllField">
                <Button text="Back" type="outline" @click="$emit('handleBackSection')" />
                <Button :loader="loadingState.submit" text="Save & Next" type="primary" @click="handleSubmit(hasErrors)" />
            </div>
        </FormulateForm>
    </div>
</template>

<script>
import Button from "@shared/components/button";
import { createAndUpdateClientAddress, getAddresses, getCountryList } from "../services";
import Loader from "@shared/loader";
import Dropdown from "@shared/components/dropdown-base";
import { checkPermission } from "@shared/utils/functions";

export default {
    name: "client-admin-address",
    components: {
        Button,
        Loader,
        Dropdown,
    },
    props: {
        isClientOnboarded: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            address: {
                primary_address: {
                    line_1: "",
                    line_2: "",
                    city: "",
                    country: "",
                    zip_code: "",
                },
                billing_address: {
                    line_1: "",
                    line_2: "",
                    city: "",
                    country: "",
                    zip_code: "",
                },
            },
            loadingState: {
                address: false,
                country: false,
                submit: false,
            },
            clientId: null,
            countries: [],
            saveAsPrimary: false,
            showCountryError: false,
            billingCountryError: false,
        };
    },
    async mounted() {
        await this.getClientAddress();
        await this.fetchCountry();
    },
    computed: {
        // disableAllField(){
        //     return !this.checkPermission('client.edit') || false
        // }
        disableAllField() {
            // if(this.$route.name === 'create-client' && !this.isClientOnboarded)  return this.checkPermission('client.create')
            if (this.$route.name === "client-details" && !this.isClientOnboarded) return this.checkPermission("client.create");
            else if (this.$route.name === "client-details" && this.isClientOnboarded) return this.checkPermission("client.edit");
            else return false;
        },
        checkBillingAddressRequired() {
            let { line_1, city, zip_code, country, line_2 } = this.address.billing_address;
            return line_1?.trim().length > 0 || city?.trim().length > 0 || zip_code?.trim().length > 0 || line_2?.trim().length > 0 || country?.trim().length > 0;
        },
    },
    methods: {
        checkPermission,
        async fetchCountry() {
            this.loadingState.country = true;
            this.countries = await getCountryList();
            this.loadingState.country = false;
        },
        async getClientAddress() {
            this.loadingState.address = true;
            this.clientId = this.$route.params.clientId;
            if (this.clientId) {
                try {
                    let response = await getAddresses(this.clientId);
                    this.address = this.convertResponse(response);
                    this.saveAsPrimary = this.address.primary_address.same_as_primary;
                } catch (error) {
                    console.log(error);
                }
            }
            this.loadingState.address = false;
        },
        convertResponse(addresses) {
            if (addresses.length > 0) {
                let primary_address = addresses?.find((el) => el.address_type === "Primary Address");
                let billing_address = addresses?.find((el) => el.address_type === "Billing Address");
                let address = {
                    primary_address,
                    billing_address,
                };
                return address;
            } else {
                return this.address;
            }
        },
        async handleSubmit(hasErrors) {
            this.loadingState.submit = true;
            if(!this.saveAsPrimary){
                if(!this.address.primary_address.country){
                    this.showCountryError = true;
                } else {
                    this.showCountryError = false;
                }
                if(this.checkBillingAddressRequired){
                    if(!this.address.billing_address.country){
                        this.billingCountryError = true;
                    } else {
                        this.billingCountryError = false;
                    }
                } else {
                    this.billingCountryError = false;
                }
            } else {
                if(!this.address.primary_address.country){
                    this.showCountryError = true;
                } else {
                    this.showCountryError = false;
                }
                if(this.checkBillingAddressRequired){
                    if(!this.address.billing_address.country){
                        this.billingCountryError = true;
                    } else {
                        this.billingCountryError = false;
                    }
                } else {
                    this.billingCountryError = false;
                }
            }

            if(this.showCountryError || this.billingCountryError){
                this.loadingState.submit = false;
                return;
            }
            if (hasErrors) {
                this.$toast.error(`Please fill all the required fields`);
            } else {
                let payload = [];
                payload.push({ ...this.address.primary_address, address_type: "Primary Address", same_as_primary: this.saveAsPrimary });
                payload.push({ ...this.address.billing_address, address_type: "Billing Address", same_as_primary: this.saveAsPrimary });
                try {
                    let response = await createAndUpdateClientAddress(this.clientId, payload);
                    this.$emit("handleNextSection");
                    this.$toast.success(response.message || "Saved!");
                } catch (error) {
                    this.$toast.error(error?.response?.data?.detail || "Failed to Update address");
                }
            }
            this.loadingState.submit = false;
        },
        updateCountry(value, type) {
            this.address = { ...this.address, [type]: { ...this.address[type], country: value ? value.name : "" } };
        },
        sameAsPrimaryAddress(event) {
            event && (this.address.billing_address = { ...this.address.primary_address, address_type: "Billing Address", id: this.address.billing_address.id, address_id: this.address.billing_address.address_id });
        },
    },
};
</script>

<style lang="scss" scoped>
.dropdown {
    min-height: 40px !important;
}
.countryErrorMsg{
    color: #960505;
    font-size: .8em;
    font-weight: 300;
}
</style>
